import LayoutA1 from "../../components/layout-a1"
import React from "react"
import "./enroll-info-detail.css"

export default class EnrollInfoDetail extends React.Component {
  render() {
    return(
      <LayoutA1 location={this.props.location} >
        <div className="profile-body">
        <div className="profile-container">
        <div className="enroll-info-container">
          <div className="info-title">报名信息</div>
          <div className="name">张迈</div>
          <div className="idNum">
            <img alt="证件号" src={require('./images/shouji.svg')}/>
            <div>320123100920100039</div>
          </div>
          <div className="phoneNum">
            <img alt="手机号" src={require('./images/shouji.svg')}/>
            <div>18556098704</div>
          </div>
          {/*<div style={{borderBottom:'1px solid #e5e5e5'}}></div>*/}
          <div className="confirm-racer-info">
            <div>确认选手信息</div>
            <div className="center-line"></div>
          </div>
          <div className="racer-enroll-info">
            <div className="racer-enroll-info-title">
              <div className="title-vertical-line" ></div>
              <div>证件信息</div>
            </div>
            <div>
              <div className="info-item-left">
                <div className="info-line">
                  <div className="item1">姓名:</div>
                  <div className="item2">张迈</div>
                </div>
                <div className="info-line">
                  <div className="item1">证件类型:</div>
                  <div className="item2">暂无</div>
                </div>
                <div className="info-line">
                  <div className="item1">出生日期:</div>
                  <div className="item2">暂无</div>
                </div>
              </div>
              <div  className="info-item-right">
                <div className="info-line">
                  <div className="item1">性别:</div>
                  <div className="item2">男</div>
                </div>
                <div className="info-line">
                  <div className="item1">证件号码:</div>
                  <div className="item2">暂无</div>
                </div>
              </div>
              <div style={{clear:'both'}}></div>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="racer-enroll-info">
            <div className="order-info-title">
              <div className="title-vertical-line"></div>
              <div >个人信息</div>
            </div>
            <div>
              <div className="info-item-left">
                <div className="info-line">
                  <div className="item1">血型:</div>
                  <div className="item2"></div>
                </div>
                <div className="info-line">
                  <div className="item1">省份:</div>
                  <div className="item2"></div>
                </div>
                <div className="info-line">
                  <div className="item1">职业:</div>
                  <div className="item2"></div>
                </div>
                <div className="info-line">
                  <div className="item1">月收入:</div>
                  <div className="item2"></div>
                </div>
              </div>
              <div className="info-item-right">
                <div className="info-line">
                  <div className="item1">国家地区:</div>
                  <div className="item2"></div>
                </div>
                <div className="info-line">
                  <div className="item1">学历:</div>
                  <div className="item2"></div>
                </div>
                <div className="info-line">
                  <div className="item1">居住地:</div>
                  <div className="item2"></div>
                </div>
                <div className="info-line">
                  <div className="item1"></div>
                  <div className="item2"></div>
                </div>
              </div>
              <div style={{clear:'both'}}></div>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="racer-enroll-info">
            <div className="order-info-title">
              <div className="title-vertical-line"></div>
              <div >联系方式</div>
            </div>
            <div>
              <div className="info-item-left">
                <div className="info-line">
                  <div className="item1">手机号码:</div>
                  <div className="item2"></div>
                </div>
                <div className="info-line">
                  <div className="item1">紧急联系人:</div>
                  <div className="item2"></div>
                </div>
                <div className="info-line">
                  <div className="item1">联系人关系:</div>
                  <div className="item2"></div>
                </div>

              </div>
              <div className="info-item-right">
                <div className="info-line">
                  <div className="item1">邮箱:</div>
                  <div className="item2"></div>
                </div>
                <div className="info-line">
                  <div className="item1">联系人电话:</div>
                  <div className="item2"></div>
                </div>
              </div>
              <div style={{clear:'both'}}></div>
            </div>
          </div>
          <div className="border-line"></div>
          <div className="order-info">
            <div className="order-info-title">
              <div className="title-vertical-line"></div>
              <div >其他信息</div>
            </div>
            <div>
              <div className="info-item-left">
                <div className="info-line">
                  <div className="item1">上传成绩证书:</div>
                  <div className="item2">已上传图片</div>
                </div>
              </div>
              <div style={{clear:'both'}}></div>
            </div>
          </div>
        </div>
        </div>
        </div>
      </LayoutA1>
    )
  }
}